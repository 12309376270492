<template>
  <div id="user-view">
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Datei auswählen</span>
              <span class="text--secondary text-xs">Wählen Sie eine CSV-Datei aus.</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Felder zuordnen</span>
              <span class="text--secondary text-xs">Ordnen Sie die Felder zu.</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="activeStep > 3"
          step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Vorschau / Speichern</span>
              <span class="text--secondary text-xs">Speichern Sie Ihre Zielgruppe.</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">

          <div class="text-center" v-if="loading">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-form v-else ref="step1Form">
            <v-card
              class="mb-8 pt-3"
              color=""
            >
              <v-row >
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    accept=".csv"
                    hide-details
                    placeholder="CSV wählen (Eigener Bestand)"
                    ref="file"
                    v-on:change="onFileSelected"
                    id="formFile"
                    outlined
                    :rules="fileUploadRules"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="text-center" v-if="loading">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-form v-else ref="step2Form">
            <v-card
              class="mb-8 pt-3"
              color=""
            >
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="form.mainData.csvTrennzeichen"
                    hide-details="auto"
                    outlined
                    :items="csvDelimeters"
                    item-text="label"
                    item-value="value"
                    label="Trennzeichen"
                    @change="saveData(true)"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="form.mainData.csvZeichensatz"
                    hide-details="auto"
                    outlined
                    :items="csvCharsets"
                    item-text="label"
                    item-value="value"
                    label="Zeichensatz"
                    @change="saveData(true)"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="form.mainData.csvStrassenFormat"
                    hide-details="auto"
                    outlined
                    :items="csvStreetFormats"
                    item-text="label"
                    item-value="value"
                    label="Hausnummer"
                    @change="saveData(true)"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="form.mainData.datenschutzQuelle"
                    hide-details="auto"
                    outlined
                    :items="dataSources"
                    item-text="label"
                    item-value="value"
                    label="Herkunftsangabe"
                    @change="saveData(true)"
                  ></v-select>
                </v-col>


                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    v-model="form.mainData.land"
                    hide-details="auto"
                    outlined
                    :items="countries"
                    item-text="label"
                    item-value="value"
                    label="Land"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row >
                <v-col
                  v-for="(requiredCsvHeaderItem,requiredCsvHeader) in requiredCsvHeaders"
                  cols="12"
                  :md="requiredCsvHeaderItem.colMd"
                >
                  <v-select
                    v-model="form.mainData.selectedHeaderColumns[requiredCsvHeader]"
                    hide-details="auto"
                    outlined
                    :items="uploadedHeaderColumns"
                    item-text="field"
                    item-value="index"
                    :label="requiredCsvHeaderItem.displayName + (requiredCsvHeaderItem.required ? ' *' : '')"
                    :rules="requiredCsvHeaderItem.required ? [numberRules] : []"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card
            class="mb-8 pt-3"
            color=""
          >
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left text-uppercase" v-for="(requiredCsvHeaderItem,requiredCsvHeader) in requiredCsvHeaders">
                    {{requiredCsvHeaderItem.displayName}}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="previewRow in previewRows">
                  <td v-for="(requiredCsvHeaderItem,requiredCsvHeader) in requiredCsvHeaders">
                    {{ previewRow[form.mainData.selectedHeaderColumns[requiredCsvHeader]] }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <template v-if="activeStep == 3">
      <div class="text-center" v-if="loading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-form v-else ref="step3Form">
        <v-card class="mt-5" outlined>
          <v-card-title>Selektion speichern</v-card-title>
          <v-card-text>
            <v-row class="" >
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="form.mainData.titel"
                  label="Bitte Name für die Zielgruppen-Selektion vergeben"
                  type="text"
                  outlined
                  dense
                  hide-details="auto"
                  required
                  class="nameField"
                  :rules="[stringRules]"
                  :error-messages="errorMessages.titel"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="" >
              <v-col
                cols="12"
                md="12"
                v-if="form.mainData.datenschutzQuelle == 'forAllRecordsTheSame'"
              >
                <v-textarea
                  maxlength="300"
                  v-model="form.mainData.datenschutzText"
                  label="Datenschutztext"
                  type="text"
                  outlined
                  dense
                  hide-details="auto"
                  required
                  :rules="[stringRules]"
                  :error-messages="errorMessages.titel"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </template>


    <div class="d-flex justify-space-between mt-10">
      <template v-if="activeStep > 1">
        <v-btn
          class="secondary"
          @click="activeStep -= 1"

        >
          Zurück
        </v-btn>
      </template>
      <template v-else>
        <v-spacer></v-spacer>
      </template>

      <v-btn
        color="accent"
        class="me-2"
        @click="saveData(false)"
      >
        <template v-if="activeStep < 3">Weiter</template>
        <template v-else>Speichern</template>
      </v-btn>
    </div>



    <v-dialog
      v-model="dialogInvalidRows"
      width="1200px"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>CSV-Datei enthält Fehler</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogInvalidRows">
            <v-icon size="18" >
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pr-10 pl-10">
          <v-alert
            border="left"
            colored-border
            text
            color="secondary"
          >
            Fehlerhafte Zeilen: <span class="error--text">{{invalidRows.length}}</span><br>
            Korrekte Zeilen: <span class="success--text">{{totalRowsCount - invalidRows.length}}</span>
          </v-alert>
          Details:
          <v-row v-for="(row, index) in invalidRows" :key="index" class="mb-4">
            <v-col>
              <v-card outlined>
                <v-card-title class="headline">
                  Zeile: {{ row.line }}
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-alert
                      border="left"
                      colored-border
                      text
                      color="error"
                      v-for="(item, idx) in row.data" :key="idx" class="mb-2"
                    >
                      <v-row >
                        <v-col cols="12" md="6">
                          <v-row>
                            <v-col cols="12" md="12">
                              <strong>Spalte:</strong> {{ item.header }}<br>
                              <strong>Zuordnung:</strong> {{ item.col }}<br>
                              <strong>Wert:</strong> {{ item.value || '(leer)' }}
                            </v-col>

                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6" >
                          <strong>Fehlermeldungen:</strong>
                          <v-list dense>
                            <v-list-item v-for="(error, errorIndex) in item.errors" :key="errorIndex">
                              <v-list-item-content>
                                <v-list-item-title>
                                  Typ: {{ error.type }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="error.type === 'min'">
                                  Minimale Länge: {{ error.min }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="error.type === 'max'">
                                  Maximale Länge: {{ error.max }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="error.type === 'allowedValues'">
                                  Erlaubte Werte: {{ error.allowedValues.join(", ") }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-between">
          <v-btn
            color="error"
            @click="resetImportProcess"
          >
            Import Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="continueImportProcess"
            v-if="previewRows.length > 0"
          >
            Nur die korrekten Zeilen importieren ({{totalRowsCount - invalidRows.length}})
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance,nextTick} from '@vue/composition-api'
import router from '@/router'
import axios from "axios";
import themeConfig from '@themeConfig'

import {
  mdiClose
} from '@mdi/js'

export default {
  setup(props) {

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const csvDelimeters = ref([
      {label: 'Semikolon (;)', value: ';'},
      {label: 'Komma (,)', value: ','},
      {label: 'Tab', value: '\t'},
    ]);
    const csvCharsets = ref([
      {label: 'UTF-8', value: 'UTF-8'},
      {label: 'Windows-1252', value: 'Windows-1252'},
    ]);
    const csvStreetFormats = ref([
      {label: 'Separat', value: 'separately'},
      {label: 'In Straße enthalten', value: 'together'},
    ]);
    const dataSources = ref([
      {label: 'Eine Herrkunftsangabe für alle Adressen', value: 'forAllRecordsTheSame'},
      {label: 'Individuelle Herrkunftsangabe', value: 'forEachRecordSeparate'},
    ]);
    const countries = ref([
      {label: 'Deutschland', value: 'de'},
    ]);
    const loading = ref(false)
    const dialogInvalidRows = ref(false)
    const invalidRows = ref([]);
    const totalRowsCount = ref(0);
    const requiredCsvHeaders = ref([]);
    const uploadedHeaderColumns = ref([]);
    const activeStep = ref(1);
    const previewRows = ref([]);
    const errorMessages = ref({
      titel : ''
    });
    const selectedFile = ref("")
    const step1Form = ref(null)
    const step2Form = ref(null)
    const step3Form = ref(null)

    const userData = JSON.parse(localStorage.getItem('userData'));
    const privacyText = `Ihre Adresse stammt von: ${userData.firma}, ${userData.strasse} ${userData.hausNr}, ${userData.plz} ${userData.ort}. Sie können der Nutzung Ihrer Daten zu Werbezwecken unter o.g. Anschrift jederzeit mit künftiger Wirkung widersprechen.`;

    const form = ref({})
    form.value = {
      mainData: {
        idPtZielgruppe: router.currentRoute.params.idPtZielgruppe,
        titel: '',
        datenschutzQuelle: 'forAllRecordsTheSame',
        datenschutzText: privacyText,
        csvTrennzeichen: ';',
        csvZeichensatz: 'UTF-8',
        csvStrassenFormat: 'separately',
        land: 'de',
        selectedHeaderColumns: Object.fromEntries(requiredCsvHeaders.value.map(header => [header, ''])),
        uploadedFile: [],
      },
    };

    const fileUploadRules = [
      value => !!value || 'Bitte wählen Sie eine Datei aus',
      value => !value || value.size < 20000000 || 'Die Datei darf maximal nur 20 MB groß sein.',
      value => !value || ["text/csv", "text/text", "application/csv", "application/vnd.ms-excel"].includes(value.type) || 'Bitte wählen Sie eine CSV-Datei aus.',
    ];

    const numberRules = (value) => (typeof value === 'number') || 'Bitte wählen';
    const stringRules = (value) => !!value.trim() || 'Bitte ausfüllen';


    const closeDialogInvalidRows = () => {
      dialogInvalidRows.value = false;
      invalidRows.value = [];
      totalRowsCount.value = 0;
    }

    const resetImportProcess = () => {
      closeDialogInvalidRows()
      activeStep.value = 1;
      totalRowsCount.value = 0;
    }

    const continueImportProcess = () => {
      closeDialogInvalidRows()
      activeStep.value = activeStep.value+ 1;
    }

    const onFileSelected = ((file) => {
      try {
        selectedFile.value = file;
      } catch (e) {
        loading.value = false;
        vm.$toastr.e(e.message, e.message);
      }
    });

    const fetchTargetGroupsRequiredCsvHeaders = () => {
      axios.get('/api/ajaxServer/?aktion=fetchTargetGroupsRequiredCsvHeaders&csvStrassenFormat='+form.value.mainData.csvStrassenFormat+'&datenschutzQuelle='+form.value.mainData.datenschutzQuelle).then((response) => requiredCsvHeaders.value = response.data.requiredCsvHeaders)
    }

    onMounted(()=>{
      fetchTargetGroupsRequiredCsvHeaders();
    })

    const validateForm = () => {
      let isValidStep = false;

      if (activeStep.value === 1) {
        isValidStep = step1Form.value.validate();
      }
      else if (activeStep.value === 2) {
        isValidStep = step2Form.value.validate();
      }
      else if (activeStep.value === 3) {
        isValidStep = step3Form.value.validate();
      }
      return isValidStep;
    }

    const saveData = (reload) => {


      if(!reload){
        if (!validateForm()) {
          return;
        }
      }
      else{
        fetchTargetGroupsRequiredCsvHeaders();
      }

      loading.value = true;

      const fd = new FormData();

      if (activeStep.value === 1) {
        fd.append('csvDatei', selectedFile.value, selectedFile.name);
      }

      if(reload){
        fd.append('aktion', 'createTargetGroupByUploadStep'+activeStep.value);
      }
      else{
        fd.append('aktion', 'createTargetGroupByUploadStep'+activeStep.value+'Check');

      }

      fd.append('mainData[titel]', form.value.mainData.titel);
      fd.append('mainData[datenschutzQuelle]', form.value.mainData.datenschutzQuelle);
      fd.append('mainData[datenschutzText]', form.value.mainData.datenschutzText);
      fd.append('mainData[csvTrennzeichen]', form.value.mainData.csvTrennzeichen);
      fd.append('mainData[csvZeichensatz]', form.value.mainData.csvZeichensatz);
      fd.append('mainData[csvStrassenFormat]', form.value.mainData.csvStrassenFormat);
      fd.append('mainData[land]', form.value.mainData.land);
      fd.append('mainData[selectedHeaderColumns]', JSON.stringify(form.value.mainData.selectedHeaderColumns));
      fd.append('mainData[uploadedFile]', form.value.mainData.uploadedFile);

      axios.post('/api/zielgruppe/', fd)
        .then((res) => {
          loading.value = false;

          if (res.data.valid === true) {

            if(!reload && (res.data.invalidRows == undefined || res.data.invalidRows.length == 0)){
              activeStep.value = activeStep.value+ 1;
            }

            if(res.data.invalidRows !== undefined && res.data.invalidRows.length > 0){
              dialogInvalidRows.value = true;
              invalidRows.value = res.data.invalidRows;
              totalRowsCount.value = res.data.totalRowsCount;
            }

            if(res.data.uploadedHeaderColumns !== undefined){
              const tmpArr = [];
              tmpArr.push({
                index: "",
                field: "",
              })
              res.data.uploadedHeaderColumns.forEach(item => {
                tmpArr.push({
                  index: item.index,
                  field: new TextDecoder().decode(Uint8Array.from(atob(item.field), c => c.charCodeAt(0))),
                })
              })
              uploadedHeaderColumns.value = tmpArr;
            }

            if(res.data.uploadedFile !== undefined){
              form.value.mainData.uploadedFile = res.data.uploadedFile;
            }

            if(res.data.matchedHeaderColumns !== undefined){
              form.value.mainData.selectedHeaderColumns = res.data.matchedHeaderColumns;
            }

            if(res.data.previewRows !== undefined){
              const tmpArr = [];
              res.data.previewRows.forEach(row => {
                const newRow = [];
                row.forEach(value => {
                  newRow.push(new TextDecoder().decode(Uint8Array.from(atob(value), c => c.charCodeAt(0))));
                });
                tmpArr.push(newRow);
              });
              previewRows.value = tmpArr;
            }

            if(activeStep.value === 4){
              router.push('/zielgruppe', () => {
                vm.$toastr.s(res.data.message.text, res.data.message.title);
              })
            }
            else{

              nextTick(() => {
                validateForm();
              })

            }

          } else if (res.data.errors !== undefined) {
            res.data.errors.forEach(item => {
              errorMessages.value[item.errorField] = item.errorTitle;
            })
          }
        })
        .catch((error) => {
          console.log(error);
          loading.value = false;
        })
    }

    return {
      saveData,
      loading,
      form,
      selectedFile,
      onFileSelected,
      fileUploadRules,
      activeStep,
      csvDelimeters,
      csvCharsets,
      csvStreetFormats,
      dataSources,
      countries,
      step1Form,
      step2Form,
      step3Form,
      requiredCsvHeaders,
      uploadedHeaderColumns,
      numberRules,
      stringRules,
      errorMessages,
      previewRows,
      dialogInvalidRows,
      closeDialogInvalidRows,
      invalidRows,
      totalRowsCount,
      resetImportProcess,
      continueImportProcess,
      icons:{
        mdiClose
      }
    }

  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.nameField{
  width: auto !important;
  flex:unset;
}

.v-card__subtitle, .v-card__text #chart-sales-overview {
  font-size: unset;
}

.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.bgGray,.bgGray .v-list{
  background: #f7f7f7;
}


</style>
